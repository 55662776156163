import useDeviceType from '@/hooks/useDeviceType';
import { Icon, Text } from '@/components/atoms';
import styles from './servicesSlider.module.scss';
import { Slider, ServicesBadge } from '@/components/molecules';
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

const ServicesSlider = () => {
  const { isDesktop } = useDeviceType();
  const ribbonBenefits = [
    {
      id: '0',
      title: 'Envío gratis',
      description: 'Envios gratis en compras desde $10.000',
      icon: (
        <Icon
          type="ben-truck"
          size={50}
          color="var(--color-primary)"
          center
        />
      )
    },
    {
      id: '1',
      title: 'Compra en cuotas',
      description:
        'Pagá con débito, MODO o en cuotas sin interés con tarjetas bancarizadas',
      icon: (
        <Icon
          type="ben-card"
          size={50}
          color="var(--color-primary)"
          center
        />
      )
    },
    {
      id: '2',
      title: 'Compra protegida',
      description: 'Protegemos los datos de nuestros clientes en todo momento',
      icon: (
        <Icon
          type="ben-shield"
          size={50}
          color="var(--color-primary)"
          center
        />
      )
    },
    {
      id: '3',
      title: 'Primer cambio gratis',
      description: 'Tu primer cambio gratis a domicilio',
      icon: (
        <Icon
          type="ben-change"
          size={50}
          color="var(--color-primary)"
          center
        />
      )
    }
  ];
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Text
          textColor="var(--color-white-absolute)"
          textStyle={{
            textAlign: 'center',
            marginBottom: '10px'
          }}
          textSize="l"
        >
          Nuestros Servicios
        </Text>
      </div>
      {!isDesktop ? (
        <Slider
          withoutChevrons
          withDots
        >
          {ribbonBenefits.map((r) => (
            <ServicesBadge
              title={r.title}
              description={r.description}
              key={r.id}
              icon={r.icon}
            />
          ))}
        </Slider>
      ) : (
        <div className={styles.desktop}>
          {ribbonBenefits.map((r) => (
            <ServicesBadge
              title={r.title}
              description={r.description}
              key={r.id}
              icon={r.icon}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ServicesSlider;
