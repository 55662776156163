'use client';

import { useEffect, useState, Suspense } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Layout from '@/components/Layout';
import {
  BrandsGallery,
  Banners,
  FooterBanners,
  CategoriesBanners
} from '@/components/organisms';
import ServicesSlider from '@/components/organisms/ServicesSlider';
import ProductSlider from '@/components/organisms/ProductSlider';
import BrandsLogoSlider from '@/components/organisms/BrandsLogoSlider';
import BannerSlider from '@/components/organisms/BannerSlider';
import MaxContainer from '@/components/atoms/MaxContainer';
import PopUp from '@/components/atoms/PopUp';
import getDataBrands from '@/brands';
import useTrackingQueue from '@/hooks/useTrackingQueue';
import brandsAPI from '@/api/brands';
import { Streaming } from '@/components/molecules';
import Skeleton from '@/components/atoms/Skeleton';
import { PRODUCT_SLIDER_ITEM } from '@/components/atoms/Skeleton/mockupSkeleton';

const HomeWrapper = ({ liveInfo, children }) => {
  const banners = useSelector((state) => state.banners);
  const [dataFetched, setDataFetched] = useState({
    brands: 'loading'
  });
  const storeId = useSelector((state) => state.store);
  const { storeTitle } = getDataBrands(storeId);
  const categories = useSelector((state) => state.navBar.categories);
  const { executeEvent } = useTrackingQueue();
  const fetchData = async () => {
    const brands = await brandsAPI.get(storeId);
    setDataFetched({
      brands: brands.success ? brands.data : []
    });
  };
  useEffect(() => {
    fetchData();
    executeEvent('viewHome');
  }, [storeId]);
  return (
    <Layout
      isHomePage
      h1InFooter={storeTitle}
    >
      <MaxContainer>
        <PopUp />
        {liveInfo?.live && <Streaming />}
        {banners?.sliders?.length > 0 && (
          <BannerSlider
            border
            priority
            banners={banners.sliders}
          />
        )}
        {(dataFetched.brands === 'loading' ||
          dataFetched.brands?.length > 0) && (
          <BrandsLogoSlider
            items={dataFetched.brands === 'loading' ? [] : dataFetched.brands}
          />
        )}
        {storeId === 'vaypol' && banners?.secondary?.length > 0 && (
          <Banners
            banners={banners.secondary}
            priority
          />
        )}

        {storeId === process.env.NEXT_PUBLIC_STORE_ID ? (
          <BrandsGallery />
        ) : (
          <CategoriesBanners categories={categories} />
        )}

        <Suspense
          fallback={
            <Skeleton
              elements={2}
              withSpinner
            >
              <ProductSlider
                items={Array(6)
                  .fill()
                  .map((_, i) => ({
                    ...PRODUCT_SLIDER_ITEM,
                    id: i + 1
                  }))}
                title="Cargando..."
              />
            </Skeleton>
          }
        >
          {children}
        </Suspense>
        {(banners?.tertiary?.length > 0 || banners?.quaternary?.length > 0) && (
          <div
            style={{
              marginBlock: '7rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem'
            }}
          >
            {banners?.tertiary?.length > 0 && (
              <FooterBanners
                banners={banners.tertiary}
                withFilter={process.env.NEXT_PUBLIC_STORE_ID === 'vaypol'}
              />
            )}
            {banners?.quaternary?.length > 0 && (
              <FooterBanners banners={banners.quaternary} />
            )}
          </div>
        )}
        <ServicesSlider />
      </MaxContainer>
    </Layout>
  );
};

HomeWrapper.propTypes = {
  liveInfo: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default HomeWrapper;
