const PRODUCT_SLIDER_ITEM = {
  all_prices: {
    original: '...',
    discount: null,
    sale_price: '...',
    installments: { quantity: 0, value: '' }
  },
  brand_icon: {
    url: '',
    blurhash: null,
    ext: 'webp'
  },
  brand_icon_white: {
    url: '',
    blurhash: null,
    ext: 'webp'
  },
  brand_name: '',
  color_counts: 1,
  dummy_images: [{ url: '' }],
  featured: {
    background_color_left: '',
    background_color_right: '',
    text_color: '',
    name: ''
  },
  gender: 'Cargando',
  is_wish_product: false,
  name: 'Cargando...',
  sku: '',
  url: ''
};

export { PRODUCT_SLIDER_ITEM };
