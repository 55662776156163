'use client';

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import styles from './bannerSlider.module.scss';
import { Image } from '@/components/atoms';
import useDeviceType from '@/hooks/useDeviceType';
import { Chips } from '@/components/molecules';
import Link from '@/components/atoms/Link';
import ThumbnailIndicator from '@/components/molecules/ProductSheetCard/ProductImageSlider/ThumbnailIndicator';
import validateVideoFile from '@/utils/validateVideoFile';

const BannerSlider = ({
  banners,
  promoBanner = false,
  interval = 5000,
  automatic = true,
  border = false,
  priority = false,
  withChevrons = false,
  withDots = true
}) => {
  const { isDesktop } = useDeviceType();
  const [autoplay, setAutoplay] = useState(true);
  const [index, setIndex] = useState(0);
  const scrolling = useRef();
  const conditionalProp = isDesktop ? 'desktop_image' : 'mobile_image';
  const sliderRef = useRef(null);
  const wrapperRef = useRef(null);
  const videoRef = useRef(null);
  const next = () => {
    const wrapper = wrapperRef.current;
    const slider = sliderRef.current;
    if (slider) {
      if (
        slider.scrollLeft >=
        slider.scrollWidth - slider.children[0].offsetWidth - 20
      ) {
        setIndex(0);
        slider.scrollTo({
          left: 0,
          behavior: 'smooth'
        });
      } else {
        setIndex((prev) => prev + 1);
        slider?.scrollTo({
          left: slider.scrollLeft + wrapper.clientWidth,
          behavior: 'smooth'
        });
      }
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.load();
    }
  }, [isDesktop, banners]); // setea el autoScroll
  useEffect(() => {
    if (banners.length > 1 && autoplay && automatic) {
      const autoScroll = setInterval(next, interval);
      // elimina el autoScroll
      return () => {
        clearInterval(autoScroll);
      };
    }
    return () => {};
  }, [autoplay]);

  const back = () => {
    const wrapper = wrapperRef.current;
    const slider = sliderRef.current;
    if (slider) {
      if (slider.scrollLeft <= 0) {
        setIndex(banners.length - 1);
        slider.scrollTo({
          left: wrapper.clientWidth * banners.length,
          behavior: 'smooth'
        });
      } else {
        setIndex((prev) => prev - 1);
        slider.scrollTo({
          left: slider.scrollLeft - wrapper.clientWidth,
          behavior: 'smooth'
        });
      }
    }
  };

  const sliderClass = cn({
    [styles.slider]: true,
    [styles.border]: border
  });

  const goToImage = (i) => {
    setIndex(i);
    const wrapper = wrapperRef.current;
    const slider = sliderRef.current;
    slider?.scrollTo({
      left: wrapper.clientWidth * i,
      behavior: 'smooth'
    });
  };
  useEffect(() => {
    goToImage(0);
    const video = document.getElementById('videoBanner');
    if (video) {
      video.load();
    }
  }, [isDesktop, banners]);

  const onScroll = () => {
    const slider = sliderRef.current;
    if (scrolling) {
      clearTimeout(scrolling.current);
    }
    scrolling.current = setTimeout(() => {
      if (slider) {
        if (Math.floor(slider.scrollLeft / slider.clientWidth) % 2 !== 0) {
          goToImage(Math.floor(slider.scrollLeft / slider.clientWidth));
        }
        if (
          Math.ceil(slider.scrollLeft / slider.clientWidth) -
            slider.scrollLeft / slider.clientWidth <
          0.4
        ) {
          goToImage(Math.ceil(slider.scrollLeft / slider.clientWidth));
        }
      }
    }, 100);
  };

  const renderSliders = (b, i) => {
    const videoValidation = validateVideoFile(b[conditionalProp]?.ext);

    return (
      <>
        {videoValidation ? (
          <div
            style={{
              width: '100%',
              height: isDesktop ? '26rem' : '100vw'
            }}
          >
            <video
              style={{ width: '100%', height: '100%' }}
              ref={videoRef}
              id="videoBanner"
              playsInline
              autoPlay
              muted
              onPlay={() => setAutoplay(false)}
              onEnded={(e) => {
                e.target.play();
                setAutoplay(true);
                next();
              }}
              className={styles.video}
            >
              <source
                src={`${b[conditionalProp]?.url}`}
                type={`video/${b[conditionalProp].ext}`}
              />
            </video>
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              height:
                (promoBanner && 'auto') || (isDesktop ? '26rem' : '100vw'),
              maxHeight: promoBanner ? '50px' : '42rem'
            }}
          >
            <Image
              fill={!promoBanner}
              imageStyles={{
                width: '100%',
                height: promoBanner ? 'auto' : '100%',
                objectFit: 'cover'
              }}
              src={isDesktop ? b?.desktop_image?.url : b?.mobile_image?.url}
              alt={`banner${b?.alt_text}`}
              heightSkeleton={isDesktop ? '261.1px' : '400px'}
              priority={i === 0 ? priority : false}
            />
          </div>
        )}
        {b.chips?.length > 0 && (
          <div className={styles.chipsInBanner}>
            <Chips
              style={{ justifyContent: 'center' }}
              simpleChip
              slugs={b.chips.map(({ name }) => name)}
              hrefs={b.chips.map(({ url }) => url)}
            />
          </div>
        )}
      </>
    );
  };

  return (
    banners?.length > 0 && (
      <div
        onMouseEnter={() => setAutoplay(false)}
        onMouseLeave={() => setAutoplay(true)}
        className={styles.container}
      >
        {withChevrons && banners?.length > 1 && (
          <button
            onClick={() => {
              back();
              setAutoplay(false);
            }}
            aria-label="Retroceder un elemento"
            className={styles.chevrons}
            style={{ left: 0 }}
          >
            <BiChevronLeft
              aria-hidden="true"
              color="white"
              fontSize={50}
            />
          </button>
        )}

        <div
          className={sliderClass}
          ref={sliderRef}
          id="slider"
          onScroll={onScroll}
        >
          <div className={styles.sliderContainer}>
            {banners?.map((b, i) => (
              <div
                key={b.url}
                className={styles.bannerWrapper}
                ref={wrapperRef}
                id="wrapper"
              >
                <div className={styles.img}>
                  {/* TODO: Como máximo en mobile 400px de alto, cambiar desde la api */}
                  {b.url ? (
                    <Link
                      href={`${b.url[0] === '/' ? b.url : `/${b.url}`}`}
                      absolutePath={b.url.includes('http')}
                      linkStyle={{ width: '100%' }}
                    >
                      {renderSliders(b, i)}
                    </Link>
                  ) : (
                    <div style={{ width: '100%', height: 'auto' }}>
                      {renderSliders(b, i)}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        {withChevrons && banners?.length > 1 && (
          <button
            onClick={() => {
              next();
              setAutoplay(false);
            }}
            aria-label="Avanzar un elemento"
            style={{ right: 0 }}
            className={styles.chevrons}
          >
            <BiChevronRight
              aria-hidden="true"
              color="white"
              fontSize={50}
            />
          </button>
        )}
        {withDots && banners?.length > 1 && (
          <ThumbnailIndicator
            images={banners}
            goToImage={goToImage}
            indexImage={index}
            showZoomModal={false}
            className={styles.indicators}
            responsive={false}
          />
        )}
      </div>
    )
  );
};

BannerSlider.propTypes = {
  banners: PropTypes.array.isRequired,
  interval: PropTypes.number,
  automatic: PropTypes.bool,
  border: PropTypes.bool,
  priority: PropTypes.bool,
  withChevrons: PropTypes.bool,
  withDots: PropTypes.bool,
  promoBanner: PropTypes.bool
};

export default BannerSlider;
